<template>
  <div>
    <vs-tabs
      :position="isSmallerScreen ? 'top' : 'left'"
      class="tabs-shadow-none"
      id="profile-tabs"
      :key="isSmallerScreen"
    >
      <!-- GENERAL -->
      <vs-tab
        icon-pack="feather"
        icon="icon-user"
        :label="
          !isSmallerScreen
            ? $t(resources.BasicInformation.i18n) ||
              resources.BasicInformation.name
            : ''
        "
      >
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <profile-information />
        </div>
      </vs-tab>
      <vs-tab
        icon-pack="feather"
        icon="icon-credit-card"
        :label="
          !isSmallerScreen
            ? $t(resources.MyWayTopay.i18n) || resources.MyWayTopay.name
            : ''
        "
      >
        <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
          <preferences-payments />
        </div>
      </vs-tab>
      <!-- <vs-tab
      icon-pack="feather"
      icon="icon-credit-card"
      :label="!isSmallerScreen ? $t(resources.CreditCard.i18n) || resources.CreditCard.name : ''"
    >
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <profile-card-register />
      </div>
    </vs-tab> -->
      <vs-tab
        icon-pack="feather"
        icon="icon-settings"
        :label="
          !isSmallerScreen
            ? $t(resources.CustomizedFee.i18n) || resources.CustomizedFee.name
            : ''
        "
        v-if="contactType == database.contactType.Ita"
      >
        <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
          <profile-config-fee />
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import ProfileInformation from "./ProfileInformation.vue";
import ProfileCardRegister from "./ProfileCardRegister.vue";
import ProfileConfigFee from "./ProfileConfigFee.vue";
import PreferencesPayments from "./PreferencesPayments.vue";
import resources from "@/i18n/resources.js";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {
    ProfileInformation,
    ProfileCardRegister,
    ProfileConfigFee,
    PreferencesPayments
  },
  data() {
    return {
      resources: resources,
      database: database,
      contactType: JSON.parse(localStorage.getItem("userInfo")).contactType
    };
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    }
  }
};
</script>
<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
