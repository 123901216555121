import { render, staticRenderFns } from "./ProfileConfigFee.vue?vue&type=template&id=9c60e840&"
import script from "./ProfileConfigFee.vue?vue&type=script&lang=js&"
export * from "./ProfileConfigFee.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/YKQo-5o4/0/virtualoffice/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9c60e840')) {
      api.createRecord('9c60e840', component.options)
    } else {
      api.reload('9c60e840', component.options)
    }
    module.hot.accept("./ProfileConfigFee.vue?vue&type=template&id=9c60e840&", function () {
      api.rerender('9c60e840', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/ProfileConfigFee.vue"
export default component.exports