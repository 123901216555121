<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <div class="vx-row justify-center">
          <div class="vx-col w-full md:w-1/2 mb-4">
            <label style="color:#CF3F32">{{$t(resources.CardNote.i18n) || resources.CardNote.name}}</label>
          </div>
        </div>

        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-right justify-right ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button
              class="mb-4 md:mb-0"
              @click="OpenPopup"
            >{{$t(resources.NewCard.i18n) || resources.NewCard.name}}</vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="listCards"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :suppressPaginationPanel="true"
          style="overflow:hidden"
        ></ag-grid-vue>
      </vx-card>
    </div>

    <vs-popup :title="$t(resources.Create.i18n) || resources.Create.name" :active.sync="popupCard">
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2">
          <vs-select
            class="w-full vs-component vs-con-input-label vs-input is-label-placeholder"
            icon-no-border
            :placeholder="$t(resources.CardType.i18n) || resources.CardType.name"
            v-model="cardType"
          >
            <vs-select-item
              :key="index"
              :value="item.cardId"
              :text="item.name"
              v-for="(item, index) in cardTypeOption"
            />
          </vs-select>
        </div>
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            icon-no-border
            :label-placeholder="$t(resources.CardNumber.i18n) || resources.CardNumber.name"
            v-model="cardNumber"
            :value="cardNumber"
            :maxlength="16"
            @keypress="onlyNumber"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/3">
          <vs-select
            class="w-full input-select-con vs-component vs-con-input-label vs-input is-label-placeholder"
            icon-no-border
            :placeholder="$t(resources.ExpirationYear.i18n) || resources.ExpirationYear.name"
            v-model="expirationYear"
            v-on:change="expirationYearChange($event)"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in expirationYearOption"
            />
          </vs-select>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <vs-select
            class="w-full input-select-con vs-component vs-con-input-label vs-input is-label-placeholder"
            icon-no-border
            :placeholder="$t(resources.ExpirationMonth.i18n) || resources.ExpirationMonth.name"
            v-model="expirationMonth"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in expirationMonthOption"
            />
          </vs-select>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <vs-input
            type="password"
            class="w-full"
            icon-pack="feather"
            icon="icon-lock"
            icon-no-border
            :label-placeholder="$t(resources.SecurityCode.i18n) || resources.SecurityCode.name"
            v-model="securityCode"
            @keypress="onlyNumber"
            :maxlength="4"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label-placeholder="$t(resources.Names.i18n) || resources.Names.name"
            v-model="names"
            :maxlength="30"
          />
        </div>
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label-placeholder="$t(resources.Surnames.i18n) || resources.Surnames.name"
            v-model="surnames"
            :maxlength="30"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            icon-no-border
            :label-placeholder="$t(resources.DocumentNumber.i18n) || resources.DocumentNumber.name"
            v-model="documentNumber"
            :maxlength="20"
            @keypress="onlyNumber"
          />
        </div>
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            type="email"
            class="w-full"
            icon-pack="feather"
            icon="icon-mail"
            icon-no-border
            :label-placeholder="$t(resources.Email.i18n) || resources.Email.name"
            v-model="Email"
            :maxlength="50"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2 mb-1 mt-4">
          <v-select
            v-model="country"
            :clearable="true"
            :options="countryOption"
            :reduce="country => country.id"
            label="name"
            :placeholder="
                $t(resources.Country.i18n) || resources.Country.name
              "
            v-on:input="getStateGetByCountry(country)"
            :hint="`${country}`"
          />
        </div>
        <div class="vx-col w-full md:w-1/2 mb-1 mt-4">
          <v-select
            v-model="state"
            :clearable="true"
            :options="stateOption"
            :reduce="state => state.id"
            label="name"
            :placeholder="$t(resources.State.i18n) || resources.State.name"
            v-on:input="getCityGetByState(state)"
            :hint="`${state}`"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2 mb-1 mt-4">
          <v-select
            v-model="residenceCity"
            :clearable="true"
            :options="cityOption"
            :reduce="state => state.id"
            label="name"
            :placeholder="
                $t(resources.ResidenceCity.i18n) || resources.ResidenceCity.name
              "
          />
        </div>
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-map-pin"
            icon-no-border
            :label-placeholder="$t(resources.Address.i18n) || resources.Address.name"
            v-model="address"
            :maxlength="150"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            icon-no-border
            :label-placeholder="$t(resources.ZipCode.i18n) || resources.ZipCode.name"
            v-model="zipCode"
            @keypress="onlyNumber"
            :maxlength="6"
          />
        </div>
        <div class="vx-col w-full md:w-1/2 mt-base">
          <div class="vx-col w-full">
            <vs-checkbox
              v-model="isFavorite"
            >{{$t(resources.IsFavorite.i18n) || resources.IsFavorite.name}}</vs-checkbox>
          </div>
        </div>
      </div>

      <div class="vx-row mb-1" style="text-align: center; margin-top: 15px;">
        <div class="vx-col w-full">
          <vs-button
            class="mr-3 mb-2"
            style
            @click="SavedInformation"
          >{{$t(resources.Save.i18n) || resources.Save.name}}</vs-button>
          <vs-button
            class="mr-3 mb-2"
            style
            color="rgb(174,174,174)"
            @click="popupCard = false"
          >{{$t(resources.Cancel.i18n) || resources.Cancel.name}}</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";

export default {
  components: {
    AgGridVue,
    "v-select": vSelect
  },
  data() {
    return {
      alerts: alerts,
      resources: resources,
      orderNumber: "",
      popupActive: false,
      invoice: "",
      contactId: JSON.parse(localStorage.getItem("userInfo")).id,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      start_date: null,
      end_date: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("IsFavorite"),
          field: "isFavorite",
          filter: true,
          width: 120,
          cellRenderer: function(event) {
            let object = "";
            switch (event.node.data.isFavorite) {
              case false:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              case true:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
            }
            return object;
          }
        },
        {
          headerName: this.$i18n.t("CardNumber"),
          field: "cardNumber",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("ExpirationDate"),
          field: "expirationMonth",
          filter: true,
          width: 120,
          cellRenderer: function(event) {
            return `${event.node.data.expirationYear}/${event.node.data.expirationMonth}`;
          }
        },
        {
          headerName: this.$i18n.t("Names"),
          field: "name",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("Surnames"),
          field: "lastName",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("Email"),
          field: "email",
          filter: true
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true,
          width: 150
        }
      ],
      listCards: [],
      popupCard: false,
      Email: "",
      cardType: "",
      cardTypeOption: [],
      cardOwner: "",
      expirationMonth: "",
      currentYear: 0,
      currentMonth: 0,
      expirationMonthOption: [],
      expirationYear: "",
      expirationYearOption: [],
      securityCode: "",
      zipCode: "",
      names: "",
      surnames: "",
      documentNumber: "",
      Phone: "",
      country: "",
      countryOption: [],
      stateOption: [],
      cityOption: [],
      state: "",
      residenceCity: "",
      address: "",
      totalPagar: 0,
      cardNumber: "",
      isFavorite: false
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("firstname", null);
      } else this.gridOptions.columnApi.setColumnPinned("firstname", "left");
    }
  },
  created: function() {
    this.get();
    this.getCountries();
    this.getCardType();
    this.getYearsCrediCard();
  },
  methods: {
    lengthCardNumber(event) {
      if (String(event).length <= 16) {
        this.cardNumber = event;
      }
      this.$forceUpdate();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    dates_sort(a, b) {
      return a.value - b.value;
    },

    //Get list orders by ITA.
    async get() {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}CreditCard/${this.contactId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data != undefined && result.data != "") {
            this.listCards = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getCardType() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}CardType`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.cardTypeOption = [];
          } else {
            this.cardTypeOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountries() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.countryOption = [];
          } else {
            this.countryOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getStateGetByCountry(countryId) {
      if (countryId != null) {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_APIVT
          }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${countryId}`,
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + localStorage.getItem("tokenVT")
          }
        }).then(
          result => {
            if (result.data == 0) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.stateOption = [];
            } else {
              this.stateOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
    },

    async getCityGetByState(stateId) {
      if (stateId != null) {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_APIVT
          }City/GetByState/${this.$i18n.locale.toUpperCase()}/${stateId}`,
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + localStorage.getItem("tokenVT")
          }
        }).then(
          result => {
            if (result.data == 0) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.cityOption = [];
            } else {
              this.cityOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
    },

    async getYearsCrediCard() {
      var currentDate = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/");
      this.currentYear = parseInt(currentDate.split("/")[0]);
      this.currentMonth = parseInt(currentDate.split("/")[1]);

      this.expirationYearOption = [];
      this.expirationMonthOption = [];
      var iYear = 0;
      for (let item = this.currentYear; iYear <= 20; item++) {
        iYear++;
        this.expirationYearOption.push(item);
      }

      for (let item = this.currentMonth; item <= 12; item++) {
        this.expirationMonthOption.push(item);
      }
    },

    expirationYearChange(event) {
      var yearSelect = event;
      this.expirationMonthOption = [];

      if (this.currentYear === yearSelect) {
        for (let item = this.currentMonth; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      } else {
        for (let item = 1; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      }
    },

    OpenPopup() {
      this.ClearForm();
      this.popupCard = true;
    },

    ClearForm() {
      this.cardType = "";
      this.cardNumber = "";
      this.expirationYear = null;
      this.expirationMonth = null;
      this.securityCode = "";
      this.names = "";
      this.surnames = "";
      this.documentNumber = "";
      this.Email = "";
      this.country = null;
      this.state = null;
      this.residenceCity = null;
      this.address = "";
      this.zipCode = "";
    },

    async SavedInformation() {
      this.$vs.loading();
      if (this.cardType != "") {
        if (this.cardNumber != "") {
          if (this.expirationYear != null) {
            if (this.expirationMonth != null) {
              if (this.securityCode != "") {
                if (this.names != "") {
                  if (this.surnames != "") {
                    if (this.documentNumber != "") {
                      if (this.Email != "") {
                        if (this.country != null) {
                          if (this.state != null) {
                            if (this.residenceCity != null) {
                              if (this.address != "") {
                                if (this.zipCode != "") {
                                  var userInfo = JSON.parse(
                                    localStorage.getItem("userInfo")
                                  );

                                  await axios({
                                    method: "POST",
                                    url: `${process.env.VUE_APP_APIVT}CreditCard/Create`,
                                    data: {
                                      creditCardTypeId: this.cardType,
                                      cardNumber: this.cardNumber,
                                      expirationYear: this.expirationYear,
                                      expirationMonth: this.expirationMonth,
                                      securityCode: this.securityCode,
                                      name: this.names,
                                      lastName: this.surnames,
                                      documentNumber: this.documentNumber,
                                      email: this.Email,
                                      contactId: userInfo.id,
                                      createdBy: userInfo.email,
                                      CityId: this.residenceCity,
                                      address: this.address,
                                      zipCode: this.zipCode,
                                      isRegistered: true,
                                      isFavorite: this.isFavorite,
                                      language: this.$i18n.locale.toUpperCase()
                                    },
                                    headers: {
                                      "content-type": "application/json",
                                      Authorization:
                                        "bearer " +
                                        localStorage.getItem("tokenVT")
                                    }
                                  }).then(
                                    result => {
                                      if (result.data.success) {
                                        this.$vs.notify({
                                          title: this.$i18n.t("Success"),
                                          text: this.$i18n.t("MsgSaved"),
                                          color: "success",
                                          iconPack: "feather",
                                          position: "top-right",
                                          icon: "icon-check"
                                        });
                                        this.popupCard = false;
                                        this.get();
                                      }
                                    },
                                    error => {
                                      this.$vs.notify({
                                        title: this.$i18n.t("Error"),
                                        text:
                                          this.$i18n.t("MsgError") +
                                          "<br/>" +
                                          error.response.data.message,
                                        color: "danger",
                                        iconPack: "feather",
                                        position: "top-right",
                                        icon: "icon-x-circle"
                                      });
                                    }
                                  );
                                } else {
                                  this.ShowNotify("EnterPlease", "ZipCode");
                                }
                              } else {
                                this.ShowNotify("EnterPlease", "Address");
                              }
                            } else {
                              this.ShowNotify(
                                "SelectedPlease",
                                "ResidenceCity"
                              );
                            }
                          } else {
                            this.ShowNotify("SelectedPlease", "SelectState");
                          }
                        } else {
                          this.ShowNotify("SelectedPlease", "SelectCountry");
                        }
                      } else {
                        this.ShowNotify("EnterPlease", "Email");
                      }
                    } else {
                      this.ShowNotify("EnterPlease", "DocumentNumber");
                    }
                  } else {
                    this.ShowNotify("EnterPlease", "Surnames");
                  }
                } else {
                  this.ShowNotify("EnterPlease", "Names");
                }
              } else {
                this.ShowNotify("EnterPlease", "SecurityCode");
              }
            } else {
              this.ShowNotify("SelectedPlease", "ExpirationMonth");
            }
          } else {
            this.ShowNotify("SelectedPlease", "ExpirationYear");
          }
        } else {
          this.ShowNotify("EnterPlease", "CardNumber");
        }
      } else {
        this.ShowNotify("SelectedPlease", "CardType");
      }
      this.$vs.loading.close();
    },

    ShowNotify(condition, input) {
      this.$vs.notify({
        title: this.$i18n.t("Alert"),
        text: this.$i18n.t(condition) + "" + this.$i18n.t(input).toLowerCase(),
        color: "warning",
        iconPack: "feather",
        position: "top-right",
        icon: "icon-alert-circle"
      });
    },

    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>