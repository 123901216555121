<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <vs-row
          vs-type="flex"
          vs-justify="center"
          v-if="this.listCards.length <= 2"
        >
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-button
              radius
              color="success"
              icon-pack="feather"
              icon="icon-plus"
              size="large"
              @click="OpenPopup"
            ></vs-button>
          </vs-col>
        </vs-row>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="listCards"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :suppressPaginationPanel="true"
          style="overflow: hidden"
        ></ag-grid-vue>
      </vx-card>
    </div>

    <vs-popup
      :title="$t(resources.Create.i18n) || resources.Create.name"
      :active.sync="popupCard"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mb-4">
          <label class="vs-input--label">{{
            $t(resources.WayToPay.i18n) || resources.WayToPay.name
          }}</label>
          <v-select
            v-model="wayToPayId"
            :clearable="true"
            :options="wayToPayList"
            :reduce="x => x.wayToPayId"
            label="name"
            v-on:input="setWayToPay(`${wayToPayId}`)"
            :hint="`${wayToPayId}`"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full mb-4">
          <label class="vs-input--label">{{
            $t(resources.Order.i18n) || resources.Order.name
          }}</label>
          <v-select
            v-model="orderId"
            :clearable="false"
            :options="orderOption"
            :reduce="x => x.value"
            label="text"
          />
        </div>
      </div>

      <div class="vx-row" v-if="wayToPayId === database.merchantType.EWallet">
        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.Select.i18n) || resources.Select.name
          }}</label>
          <v-select
            v-model="merchantId"
            :clearable="false"
            :options="walletOption"
            :reduce="data => data.walletId"
            label="name"
            v-on:input="setAccountWallet(`${merchantId}`)"
            :hint="`${merchantId}`"
          ></v-select>
        </div>
        <div
          class="vx-col md:w-1/2 w-full mb-2"
          v-if="merchantId == database.merchants.BlockPay"
        >
          <label class="vs-input--label">{{
            $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
          }}</label>
          <v-select
            v-model="accountWalletId"
            :clearable="false"
            :options="accountWalletOption"
            :reduce="x => x.id"
            label="name"
            :hint="`${accountWalletId}`"
            name="accountWalletId"
          ></v-select>
        </div>
      </div>
      <div
        class="vx-row"
        v-if="wayToPayId === database.merchantType.creditCard"
      >
        <div class="vx-col w-full md:w-1/2">
          <vs-select
            class="
              w-full
              vs-component vs-con-input-label vs-input
              is-label
            "
            icon-no-border
            :label="$t(resources.CardType.i18n) || resources.CardType.name"
            v-model="cardType"
            v-validate="'required'"
            name="cardType"
          >
            <vs-select-item
              :key="index"
              :value="item.cardId"
              :text="item.name"
              v-for="(item, index) in cardTypeOption"
            />
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('cardType')">{{
            errors.first("cardType")
          }}</span>
        </div>
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            icon-no-border
            :label="$t(resources.CardNumber.i18n) || resources.CardNumber.name"
            v-model="cardNumber"
            maxlength="16"
            v-validate="'required'"
            name="cardNumber"
          />
          <span class="text-danger text-sm" v-show="errors.has('cardNumber')">{{
            errors.first("cardNumber")
          }}</span>
        </div>

        <div class="vx-col w-full md:w-1/3">
          <vs-select
            class="
              w-full
              input-select-con
              vs-component vs-con-input-label vs-input
              is-label
            "
            icon-no-border
            :label="
              $t(resources.ExpirationYear.i18n) || resources.ExpirationYear.name
            "
            v-model="expirationYear"
            v-on:change="expirationYearChange($event)"
            v-validate="'required'"
            name="expirationYear"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in expirationYearOption"
            />
          </vs-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('expirationYear')"
            >{{ errors.first("expirationYear") }}</span
          >
        </div>
        <div class="vx-col w-full md:w-1/3">
          <vs-select
            class="
              w-full
              input-select-con
              vs-component vs-con-input-label vs-input
              is-label
            "
            icon-no-border
            :label="
              $t(resources.ExpirationMonth.i18n) ||
                resources.ExpirationMonth.name
            "
            v-model="expirationMonth"
            v-validate="'required'"
            name="expirationMonth"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in expirationMonthOption"
            />
          </vs-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('expirationMonth')"
            >{{ errors.first("expirationMonth") }}</span
          >
        </div>
        <div class="vx-col w-full md:w-1/3">
          <vs-input
            type="password"
            class="w-full"
            icon-pack="feather"
            icon="icon-lock"
            icon-no-border
            :label="
              $t(resources.SecurityCode.i18n) || resources.SecurityCode.name
            "
            v-model="securityCode"
            maxlength="4"
            v-validate="'required'"
            name="securityCode"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('securityCode')"
            >{{ errors.first("securityCode") }}</span
          >
        </div>

        <div class="vx-col w-full md:w-1/2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label="$t(resources.Names.i18n) || resources.Names.name"
            v-model="names"
            maxlength="30"
            v-validate="'required'"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label="$t(resources.Surnames.i18n) || resources.Surnames.name"
            v-model="surnames"
            maxlength="30"
            v-validate="'required'"
            name="lastName"
          />
          <span class="text-danger text-sm" v-show="errors.has('lastName')">{{
            errors.first("lastName")
          }}</span>
        </div>

        <div class="vx-col w-full md:w-1/2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            icon-no-border
            :label="
              $t(resources.DocumentNumber.i18n) || resources.DocumentNumber.name
            "
            v-model="documentNumber"
            maxlength="20"
            v-validate="'required|max:20'"
            name="documentNumber"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('documentNumber')"
            >{{ errors.first("documentNumber") }}</span
          >
        </div>
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            type="email"
            class="w-full"
            icon-pack="feather"
            icon="icon-mail"
            icon-no-border
            :label="$t(resources.Email.i18n) || resources.Email.name"
            v-model="Email"
            maxlength="50"
            v-validate="'required'"
            name="email"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{
            errors.first("email")
          }}</span>
        </div>

        <div class="vx-col w-full md:w-1/2 ">
          <label class="vs-input--label">{{
            $t(resources.Country.i18n) || resources.Country.name
          }}</label>
          <v-select
            v-model="country"
            :clearable="true"
            :options="countryOption"
            :reduce="country => country.id"
            label="name"
            v-on:input="getStateGetByCountry(country)"
            :hint="`${country}`"
          />
        </div>
        <div class="vx-col w-full md:w-1/2 ">
          <label class="vs-input--label">{{
            $t(resources.State.i18n) || resources.State.name
          }}</label>
          <v-select
            v-model="state"
            :clearable="true"
            :options="stateOption"
            :reduce="state => state.id"
            label="name"
            v-on:input="getCityGetByState(state)"
            :hint="`${state}`"
          />
        </div>

        <div class="vx-col w-full md:w-1/2 ">
          <label class="vs-input--label">{{
            $t(resources.ResidenceCity.i18n) || resources.ResidenceCity.name
          }}</label>
          <v-select
            v-model="residenceCity"
            :clearable="true"
            :options="cityOption"
            :reduce="state => state.id"
            label="name"
          />
        </div>
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-map-pin"
            icon-no-border
            :label="$t(resources.Address.i18n) || resources.Address.name"
            v-model="address"
            v-validate="'required|min:5|max:50'"
            name="address"
          />
          <span class="text-danger text-sm" v-show="errors.has('address')">{{
            errors.first("address")
          }}</span>
        </div>

        <div class="vx-col w-full md:w-1/2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            icon-no-border
            :label="$t(resources.ZipCode.i18n) || resources.ZipCode.name"
            v-model="zipCode"
            maxlength="10"
            v-validate="'required'"
            name="zipCode"
          />
          <span class="text-danger text-sm" v-show="errors.has('zipCode')">{{
            errors.first("zipCode")
          }}</span>
        </div>
      </div>

      <div class="vx-row mb-1" style="text-align: center; margin-top: 15px">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2" style @click.prevent="save()">{{
            $t(resources.Save.i18n) || resources.Save.name
          }}</vs-button>
          <vs-button
            class="mr-3 mb-2"
            style
            color="rgb(174,174,174)"
            @click="popupCard = false"
            >{{ $t(resources.Cancel.i18n) || resources.Cancel.name }}</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";
import database from "@/assets/resources/enums/database.json";
import { Validator } from "vee-validate";

export default {
  components: {
    AgGridVue,
    "v-select": vSelect,
    Validator
  },
  data() {
    return {
      alerts: alerts,
      resources: resources,
      database: database,
      orderNumber: "",
      popupActive: false,
      invoice: "",
      contactId: JSON.parse(localStorage.getItem("userInfo")).id,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      orderId: 1,
      orderOption: [
        { value: 1, text: "Primera opción" },
        { value: 2, text: "Segunda opción" }
      ],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Type"),
          field: "entity",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("Description"),
          field: "description",
          filter: true,
          width: 350
        },
        {
          headerName: this.$i18n.t("Order"),
          field: "preferOrder",
          filter: true
        }
        // {
        //   headerName: this.$i18n.t("CreationDate"),
        //   field: "creationDate",
        //   filter: true,
        //   width: 150
        // }
      ],
      listCards: [],
      popupCard: false,
      Email: "",
      cardType: "",
      cardTypeOption: [],
      cardOwner: "",
      expirationMonth: "",
      currentYear: 0,
      currentMonth: 0,
      expirationMonthOption: [],
      expirationYear: "",
      expirationYearOption: [],
      securityCode: "",
      zipCode: "",
      names: "",
      surnames: "",
      documentNumber: "",
      Phone: "",
      country: "",
      countryOption: [],
      stateOption: [],
      cityOption: [],
      state: "",
      residenceCity: "",
      address: "",
      totalPagar: 0,
      cardNumber: "",
      isFavorite: true,
      wayToPayList: [],
      wayToPayId: "",
      merchantId: "",
      walletOption: [],
      accountWalletId: "",
      accountWalletOption: "",
      eWalletPayment: {}
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("firstname", null);
      } else this.gridOptions.columnApi.setColumnPinned("firstname", "left");
    }
  },
  async created() {
    this.$vs.loading();
    await this.getWayToPay();
    await this.get();
    await this.getCountries();
    await this.getCardType();
    await this.getYearsCrediCard();
    this.$vs.loading.close();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    async save() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          if (this.wayToPayId == this.database.merchantType.creditCard) {
            await this.createCard();
          } else if (this.wayToPayId == this.database.merchantType.EWallet) {
            await this.create(25, this.accountWalletId);
          }
          await this.get();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async create(entity, identifier) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MONTHLYPAY}preferencePayment/create`,
        data: {
          contactId: this.contactId,
          entityId: entity,
          identifier: identifier,
          order: this.orderId,
          createdBy: JSON.parse(localStorage.getItem("userInfo")).email
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      })
        .then(async result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(function(error) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        });
    },

    async get() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MONTHLYPAY}preferencePayment/listByFilter`,
        data: {
          contactId: this.contactId
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data != undefined && result.data != "") {
            this.listCards = result.data.data;
            if (result.data.data.filter(x => x.preferOrder == 1).length > 0) {
              this.orderOption = this.orderOption.filter(x => x.value !== 1);
              this.orderId = 2;
            }
            if (result.data.data.filter(x => x.preferOrder == 2).length > 0) {
              this.orderOption = this.orderOption.filter(x => x.value !== 2);
              this.orderId = 1;
            }
          }
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getCardType() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}CardType`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.cardTypeOption = [];
          } else {
            this.cardTypeOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountries() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.countryOption = [];
          } else {
            this.countryOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getStateGetByCountry(countryId) {
      if (countryId != null) {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_APIVT
          }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${countryId}`,
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + localStorage.getItem("tokenVT")
          }
        }).then(
          result => {
            if (result.data == 0) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.stateOption = [];
            } else {
              this.stateOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
    },

    async getCityGetByState(stateId) {
      if (stateId != null) {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_APIVT
          }City/GetByState/${this.$i18n.locale.toUpperCase()}/${stateId}`,
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + localStorage.getItem("tokenVT")
          }
        }).then(
          result => {
            if (result.data == 0) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.cityOption = [];
            } else {
              this.cityOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
    },

    async getYearsCrediCard() {
      var currentDate = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/");
      this.currentYear = parseInt(currentDate.split("/")[0]);
      this.currentMonth = parseInt(currentDate.split("/")[1]);

      this.expirationYearOption = [];
      this.expirationMonthOption = [];
      var iYear = 0;
      for (let item = this.currentYear; iYear <= 20; item++) {
        iYear++;
        this.expirationYearOption.push(item);
      }

      for (let item = this.currentMonth; item <= 12; item++) {
        this.expirationMonthOption.push(item);
      }
    },

    async createCard() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}CreditCard/Create`,
        data: {
          id: 0,
          creditCardTypeId: this.cardType,
          cardNumber: this.cardNumber,
          expirationYear: this.expirationYear,
          expirationMonth: this.expirationMonth,
          securityCode: this.securityCode,
          name: this.names,
          lastName: this.surnames,
          documentNumber: this.documentNumber,
          email: this.Email,
          contactId: this.contactId,
          createdBy: this.emailSession,
          cityId: this.residenceCity,
          address: this.address,
          zipCode: this.zipCode,
          isRegistered: true,
          isFavorite: true,
          active: true,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check"
            });
            this.popupCard = false;
            debugger;
            const _card = result.data.data;
            await this.create(26, _card.id);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );

      this.$vs.loading.close();
    },

    async getWayToPay() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}WayToPay/GetAvailable`,
        data: {
          applicationId: 6,
          countryCode: "USA",
          productId: 15,
          supplierId: 11,
          language: this.$i18n.locale.toUpperCase(),
          currencyCode: "USD",
          contactId: this.contactId
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.cardTypeOption = [];
          } else {
            this.wayToPayList = result.data.wayToPay.filter(
              x =>
                x.wayToPayId == 3 ||
                x.wayToPayId == this.database.merchantType.EWallet
            );
            this.wayToPayId = this.database.merchantType.creditCard;
            this.setWayToPay(3);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.cardTypeOption = [];
        }
      );
    },

    setWayToPay(item) {
      let _merchantType = this.wayToPayList.filter(x => x.wayToPayId == item);

      if (item == 1) {
        this.walletOption = _merchantType[0].options[0].wallets
          .filter(y => y.walletId == 12)
          .map(x => x);
      }
      if (item == 2)
        this.bankType = _merchantType[0].options[0].banks.map(x => x);
      if (item == 3 || item == 6) {
        this.cardTypeOption = _merchantType[0].options.map(x => x);
      }
    },

    setAccountWallet(item) {
      let _accounts = this.walletOption.find(x => x.walletId == item).accounts;
      this.accountWalletOption = _accounts;
    },

    expirationYearChange(event) {
      var yearSelect = event;
      this.expirationMonthOption = [];

      if (this.currentYear === yearSelect) {
        for (let item = this.currentMonth; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      } else {
        for (let item = 1; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      }
    },

    OpenPopup() {
      this.ClearForm();
      this.popupCard = true;
    },

    ClearForm() {
      this.cardType = "";
      this.cardNumber = "";
      this.expirationYear = null;
      this.expirationMonth = null;
      this.securityCode = "";
      this.names = "";
      this.surnames = "";
      this.documentNumber = "";
      this.Email = "";
      this.country = null;
      this.state = null;
      this.residenceCity = null;
      this.address = "";
      this.zipCode = "";
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
