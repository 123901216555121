<template>
  <div>
    <vs-row vs-type="flex" vs-justify="center" class="mb-4">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-avatar
          v-if="!dataImg"
          class="user-profile-img"
          :src="imageUrl"
          size="200px"
        />
        <vs-avatar
          v-else
          class="user-profile-img"
          :src="dataImg"
          size="200px"
        />
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center">
        <div class="upload-img mt-5" v-if="!dataImg">
          <input
            type="file"
            class="hidden"
            ref="uploadImgInput"
            @change="updateCurrImg"
            accept="image/*"
          />
          <vs-button @click="$refs.uploadImgInput.click()">{{
            $t(resources.UploadFile.i18n) || resources.UploadFile.name
          }}</vs-button>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        v-if="dataImg"
      >
        <div class="upload-img mt-5">
          <vs-button @click="getuploadForm">{{
            $t(resources.Save.i18n) || resources.Save.name
          }}</vs-button>
        </div>
      </vs-col>
    </vs-row>
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <vx-card>
          <h4 class="text-center text-success mb-2">
            <strong>{{ name }} {{ lastName }}</strong>
          </h4>
          <p class="font-semibold text-center text-warning mb-4">
            <vs-icon
              icon-pack="feather"
              icon="icon-calendar"
              size="small"
              color="rgba(var(--vs-warning), 1)"
            ></vs-icon>
            {{
              $t(resources.ExpirationDate.i18n) ||
                resources.ExpirationDate.name
            }}: {{ renewalDate }} |
            {{
              $t(resources.AffiliationDate.i18n) ||
                resources.AffiliationDate.name
            }}: {{ affiliationDate }}
          </p>
          <p class="font-semibold text-center text-warning mb-base">
            <vx-tooltip
              v-if="changeRenewalDate == null || changeRenewalDate == ''"
              :text="$t(resources.Update.i18n) || resources.Update.name"
            >
              <a @click="popupRenewalDate = true">
                <feather-icon icon="EditIcon"></feather-icon><br/>Cambia aquí el día
                de pago de tu mensualidad.
              </a>
            </vx-tooltip>
          </p>

          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mb-2">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                :label="
                  $t(resources.DocumentType.i18n) || resources.DocumentType.name
                "
                :value="documentType"
                disabled
              />
            </div>

            <div class="vx-col md:w-1/3 w-full mb-4">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-hash"
                  icon-no-border
                  :label="
                    $t(resources.DocumentNumber.i18n) ||
                      resources.DocumentNumber.name
                  "
                  :value="documentNumber"
                  disabled
                />
              </div>
            </div>

            <div class="vx-col md:w-1/3 w-full mb-4">
              <div class="vx-col w-full">
                <vs-input
                  v-model="countryOperationEntryName"
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-map"
                  icon-no-border
                  disabled
                  :label="
                    $t(resources.MembershipCountry.i18n) ||
                      resources.MembershipCountry.name
                  "
                />
              </div>
            </div>

            <div class="vx-col md:w-1/3 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.BirthDay.i18n) || resources.BirthDay.name
              }}</label>
              <v-select
                v-model="birthDay"
                :clearable="false"
                :options="dayList"
                :reduce="data => data.value"
                label="text"
                v-validate="'required'"
                name="birthDay"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('birthDay')"
                >{{ errors.first("birthDay") }}</span
              >
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.BirthMonth.i18n) || resources.BirthMonth.name
              }}</label>
              <v-select
                v-model="birthMonth"
                :clearable="false"
                :options="monthList"
                :reduce="data => data.value"
                label="text"
                v-validate="'required'"
                name="birthMonth"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('birthMonth')"
                >{{ errors.first("birthMonth") }}</span
              >
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.BirthYear.i18n) || resources.BirthYear.name
              }}</label>
              <v-select
                v-model="birthYear"
                :clearable="false"
                :options="yearList"
                :reduce="data => data.value"
                label="text"
                v-validate="'required'"
                name="birthYear"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('birthYear')"
                >{{ errors.first("birthYear") }}</span
              >
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  type="email"
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-mail"
                  icon-no-border
                  :label="$t(resources.Email.i18n) || resources.Email.name"
                  v-model="email"
                />
              </div>
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-smartphone"
                  :label="$t(resources.Mobile.i18n) || resources.Mobile.name"
                  v-model="phone"
                  name="phone"
                  v-validate="'required|numeric'"
                />
              </div>
              <span class="text-danger text-sm" v-show="errors.has('phone')">{{
                errors.first("phone")
              }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <vs-select
                class="w-full"
                icon-no-border
                :label="$t(resources.Gender.i18n) || resources.Gender.name"
                v-model="gender"
                v-validate="'required'"
                name="gender"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="$t(item.i18n) || item.text"
                  v-for="(item, index) in genderOption"
                />
              </vs-select>
              <span class="text-danger text-sm" v-show="errors.has('gender')">{{
                errors.first("gender")
              }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.ResidenceCountry.i18n) ||
                  resources.ResidenceCountry.name
              }}</label>
              <v-select
                v-model="countryId"
                :clearable="false"
                :options="countryList"
                :reduce="country => country.id"
                label="name"
                v-on:input="getStateByCountry(`${countryId}`)"
                :hint="`${countryId}`"
                name="countryId"
                v-validate="'required'"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('countryId')"
                >{{ errors.first("countryId") }}</span
              >
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.ResidenceState.i18n) ||
                  resources.ResidenceState.name
              }}</label>
              <v-select
                v-model="stateId"
                :clearable="false"
                :options="stateCountry"
                :reduce="state => state.id"
                label="name"
                v-on:input="getCityByState(`${stateId}`)"
                :hint="`${stateId}`"
                v-validate="'required'"
                name="state"
              />
              <span class="text-danger text-sm" v-show="errors.has('state')">{{
                errors.first("state")
              }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.ResidenceCity.i18n) || resources.ResidenceCity.name
              }}</label>
              <v-select
                v-model="cityId"
                :clearable="false"
                :options="destinationList"
                :reduce="city => city.id"
                label="name"
                v-validate="'required'"
                name="city"
              />
              <span class="text-danger text-sm" v-show="errors.has('city')">{{
                errors.first("city")
              }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-map-pin"
                  :label="$t(resources.Address.i18n) || resources.Address.name"
                  v-model="address"
                  maxlength="99"
                  v-validate="{ required: true, regex: /^([a-zA-Z0-9- ]+)$/ }"
                  name="address"
                />
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('address')"
                >{{ errors.first("address") }}</span
              >
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-hash"
                  type="number"
                  :label="$t(resources.ZipCode.i18n) || resources.ZipCode.name"
                  v-model="zipCode"
                  v-validate="'required|min:5|numeric'"
                  name="zipCode"
                />
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('zipCode')"
                >{{ errors.first("zipCode") }}</span
              >
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.Nationality.i18n) || resources.Nationality.name
              }}</label>
              <v-select
                v-model="nationalityId"
                :clearable="false"
                :options="countryList"
                :reduce="country => country.id"
                label="name"
                name="nationalityId"
                v-validate="'required'"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('nationalityId')"
                >{{ errors.first("nationalityId") }}</span
              >
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-user"
                  :label="
                    `${$t(resources.Nickname.i18n) ||
                      resources.Nickname.name} - One2trip`
                  "
                  v-model="nickname"
                  maxlength="50"
                  v-validate="{ required: true, regex: /^([a-zA-Z0-9- ]+)$/ }"
                  name="nickname"
                />
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('nickname')"
                >{{ errors.first("nickname") }}</span
              >
            </div>
            <div class="vx-col md:w-1/4 w-full">
              <label class="vs-input--label"
                >{{
                  $t(resources.CountryOperation.i18n) ||
                    resources.CountryOperation.name
                }}
                - One2trip</label
              >
              <v-select
                v-model="countryOperationId"
                :clearable="false"
                :options="countryOperationOption"
                :reduce="data => data.countryId"
                label="display"
              >
                <template slot="option" slot-scope="option">
                  <img :src="option.iconUrl" class="h-6 w-6" />
                  {{ option.display }}
                </template>
              </v-select>
            </div>
          </div>

          <div class="vx-col mb-2 mt-5" style="text-align:right ">
            <vs-button
              color="primary"
              type="filled"
              @click.prevent="validData()"
            >
              {{ $t(resources.Save.i18n) || resources.Save.name }}
            </vs-button>
          </div>
          <vs-row vs-type="flex" vs-justify="center" class="mb-4">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="3"
            >
              <vs-button
                type="gradient"
                color="primary"
                icon-pack="material-icons"
                icon="notifications_active"
                size="large"
                :href="`https://t.me/VisionTravelbot?start=${itaCode}`"
                >Telegram</vs-button
              >
            </vs-col>
          </vs-row>
          <h4 class="text-center text-primary text-semibold mb-2">
            {{ $t(alerts.MsgJoinTelegram.i18n) || alerts.MsgJoinTelegram.name }}
          </h4>
          <h4 class="text-primary text-center">
            <strong>{{
              $t(alerts.MsgWhatWait.i18n) || alerts.MsgWhatWait.name
            }}</strong>
          </h4>
        </vx-card>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full text-right">
        <vs-button
          color="rgb(174,174,174)"
          type="filled"
          @click="$router.push('/')"
          >{{ $t(resources.Return.i18n) || resources.Return.name }}</vs-button
        >
      </div>
    </div>
    <vs-popup
      :title="
        $t(resources.ExpirationDate.i18n) || resources.ExpirationDate.name
      "
      :active.sync="popupRenewalDate"
    >
      <div class="vx-row">
        <div class="vx-col w-full mb-4">
          <label class="vs-input--label">{{
            $t(resources.Day.i18n) || resources.Day.name
          }}</label>
          <v-select
            v-model="dayRenewal"
            :clearable="false"
            :options="dayList"
            :reduce="data => data.value"
            label="text"
          ></v-select>
        </div>
      </div>
      <vs-button class="mb-base" @click="setRenewalDay">{{
        $t(resources.Save.i18n) || resources.Save.name
      }}</vs-button>
    </vs-popup>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import database from "@/assets/resources/enums/database.json";
import axios from "axios";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import { Validator } from "vee-validate";

export default {
  components: {
    vSelect,
    Validator
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      database: database,
      lang: lang,
      isNavOpen: false,
      userPoll: "",
      popupRenewalDate: false,
      dayRenewal: "",
      itaStatus: localStorage.getItem("status"),
      renewalDate: localStorage.getItem("renewalDate"),
      contactId: JSON.parse(localStorage.getItem("userInfo")).id,
      iboCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      genderOption: [
        { value: 1, text: this.$i18n.t("Female") },
        { value: 2, text: this.$i18n.t("Male") },
        { value: 3, text: this.$i18n.t("Other") }
      ],
      dayList: [],
      monthList: [],
      yearList: [],
      stateCountry: [],
      countryList: [],
      destinationList: [],
      name: "",
      lastName: "",
      documentType: 0,
      documentNumber: "",
      gender: 3,
      birthDay: "",
      birthMonth: "",
      birthYear: "",
      email: "",
      lastEmail: "",
      phone: "",
      countryOperationEntry: "",
      countryOperationEntryName: "",
      nationalityId: "",
      countryId: "",
      stateId: "",
      cityId: "",
      zipCode: "",
      address: "",
      imageUrl: "",
      itaCode: "",
      nickname: "",
      countryOperationId: 0,
      countryOperationOption: [],
      countryOperation: "",
      dataImg: null,
      dataImage: null,
      affiliationDate: "",
      changeRenewalDate: ""
    };
  },
  async created() {
    this.$vs.loading();

    this.getBirtDate();
    await this.getCountryOperation();
    await this.getCountry();
    await this.setInformation();

    this.$vs.loading.close();
  },
  methods: {
    validData() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.update();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.dataImg = e.target.result;
        };
        this.dataImage = input.target.files[0];
        reader.readAsDataURL(input.target.files[0]);
      }
    },

    async getuploadForm() {
      this.$vs.loading();
      let _user = JSON.parse(localStorage.getItem("userInfo"));
      let formData = new FormData();
      formData.set("code", _user.itaCode);
      formData.set("language", this.$i18n.locale.toLowerCase());
      formData.append("file", this.dataImage, this.dataImage.name);

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Ita/UpdateAvatar`,
        data: formData,
        headers: {
          Authorization: "bearer " + localStorage.getItem("tokenVT"),
          "Content-Type": "multipart/form-data"
        }
      }).then(
        result => {
          if (result.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            setTimeout(() => {
              this.$vs.loading.close();
              window.location.reload();
            }, 2000);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      );
    },

    getBirtDate() {
      let dates = lang[this.$i18n.locale];
      let year = new Date().getFullYear() - 18;

      for (let index = 1; index < 32; index++) {
        this.dayList.push({
          value: index < 10 ? `0${index}` : index,
          text: index < 10 ? `0${index}` : index
        });
      }

      for (let index = 0; index < dates._months.length; index++) {
        let value = index + 1 < 10 ? `0${index + 1}` : index + 1;
        this.monthList.push({ value: value, text: dates._months[index] });
      }

      for (let index = 0; index < 81; index++) {
        this.yearList.push({ value: year - index, text: year - index });
      }
    },

    async setRenewalDay() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MONTHLYPAY}ita/updateRenewalDate`,
        data: {
          itaCode: this.iboCode,
          renewalDate: this.dayRenewal
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupRenewalDate = false;
            await this.setInformation();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async update() {
      this.$vs.loading();
      let cityName = this.destinationList.find(x => x.id == this.cityId).name;
      let stateName = this.stateCountry.find(x => x.id == this.stateId).name;
      let countryName = this.countryList.find(x => x.id == this.countryId).name;
      let couOpe = this.countryOperationOption.find(
        x => x.countryId == this.countryOperationId
      );
      let _data = {};

      _data.id = this.contactId;
      _data.itaCode = this.itaCode;
      _data.name = this.name;
      _data.lastName = this.lastName;
      _data.email = this.email;
      _data.lastEmail = this.lastEmail;
      _data.phone = this.phone;
      _data.gender = this.gender;
      _data.nationalityId = this.nationalityId;
      _data.cityId = this.cityId;
      _data.address = this.address;
      _data.stateName = stateName;
      _data.countryName = countryName;
      _data.cityName = cityName;
      _data.zipCode = this.zipCode;
      _data.countryOperationId = this.countryOperationId;
      _data.countryIso2 = couOpe.countryCodeIso2;
      _data.countryOperation = couOpe.countryCode;
      _data.countryOperationEntry = this.countryOperationEntry;
      _data.birthDay = this.birthDay;
      _data.birthMonth = this.birthMonth;
      _data.birthYear = this.birthYear;
      _data.nickname = this.nickname;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}contact/update`,
        data: _data,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
          this.$vs.loading.close();
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      );
    },

    async setInformation() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Contact/${this.$i18n.locale.toUpperCase()}/${this.contactId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data) {
            let userInfo = result.data;
            let birthDate = userInfo.birthDate.split("-");

            this.countryId = userInfo.country == 0 ? "" : userInfo.country;
            await this.getStateByCountry(userInfo.country);

            this.stateId = userInfo.state == 0 ? "" : userInfo.state;
            await this.getCityByState(userInfo.state);

            this.cityId = userInfo.city == 0 ? "" : userInfo.city;

            let document = userInfo.documentNumber;
            if (userInfo.documentType == 11) document = this.$i18n.t("Private");

            this.itaCode = userInfo.itaCode;
            this.name = userInfo.name;
            this.lastName = userInfo.lastName;
            this.renewalDate = userInfo.renewalDate;
            this.documentType = userInfo.documentTypeName;
            this.documentNumber = document;
            this.countryOperationEntryName = `${userInfo.countryOperationEntry} - ${userInfo.countryOperationNameEntry}`;
            this.countryOperationEntry = userInfo.countryOperationEntry;
            this.birthDay = birthDate[2];
            this.birthMonth = birthDate[1];
            this.birthYear = birthDate[0];
            this.email = userInfo.email;
            this.lastEmail = userInfo.email;
            this.phone = userInfo.phone;
            this.gender = userInfo.gender;
            this.imageUrl = userInfo.imageUrl;
            this.address = userInfo.address;
            this.zipCode = userInfo.zipCode;
            this.nationalityId =
              userInfo.nationalityId == 0 ? "" : userInfo.nationalityId;
            this.nickname = userInfo.nickname;
            this.countryOperationId = userInfo.countryOperationId;
            this.countryOperation = userInfo.countryOperation;
            this.affiliationDate = userInfo.affiliationDate;
            this.changeRenewalDate = userInfo.changeRenewalDate;
            this.dayRenewal = userInfo.renewalDate.split("-")[2];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountryOperation() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/${
          this.database.application.OV
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryOperationOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getStateByCountry(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.stateCountry = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCityByState(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }City/GetByState/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.destinationList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
