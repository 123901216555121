<template>
  <div>
    <vx-card class="mb-4">
      <vs-row vs-type="flex" vs-justify="center" class="mb-4">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <p>{{$t(resources.FeeNote.i18n) || resources.FeeNote.name}}</p>
          <br />
          <vs-button
            color="warning"
            type="line"
            icon-pack="feather"
            icon="icon-alert-triangle"
            @click="popupInfo = true"
          >{{$t(resources.MoreInformation.i18n) || resources.MoreInformation.name}}</vs-button>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-button
            radius
            color="danger"
            type="gradient"
            icon-pack="feather"
            icon="icon-plus"
            icon-after
            size="large"
            @click="popupFee = true"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <p class>{{$t(resources.Create.i18n) || resources.Create.name}}</p>
        </vs-col>
      </vs-row>
    </vx-card>

    <div id="ag-grid-demo">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/4 mb-4">
            <vs-select
              class="vx-col w-full"
              :label="$t(resources.Status.i18n) || resources.Status.name"
              v-model="status"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="$t(item.i18n) || item.text"
                v-for="(item, index) in statusList"
              />
            </vs-select>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
            <vs-button color="primary" type="filled" @click="list()">
              {{
              $t(resources.Search.i18n) || resources.Search.name
              }}
            </vs-button>
          </div>
        </div>
        <div id="ag-grid-demo">
          <!-- TABLE ACTION ROW -->
          <div class="flex flex-wrap justify-between items-center">
            <!-- ITEMS PER PAGE -->
            <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                  class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                  <span class="mr-2">
                    {{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                    }}
                    -
                    {{
                    listData.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : listData.length
                    }}
                    of {{ listData.length }}
                  </span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                    <span>20</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                    <span>50</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                    <span>100</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                    <span>150</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>

            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                :placeholder="$t(resources.Search.i18n) || resources.Search.name"
              />
              <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
                {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
                CVS
              </vs-button>
            </div>
          </div>

          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="listData"
            rowSelection="single"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            @row-selected="onRowSelected"
          ></ag-grid-vue>
          <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
        </div>
      </vx-card>
    </div>

    <vs-popup :title="$t(resources.Create.i18n) || resources.Create.name" :active.sync="popupFee">
      <label class="vs-input--label">{{
          $t(resources.CountryOperation.i18n) || resources.CountryOperation.name
        }}</label>
        <v-select
          v-model="countryOperationId"
          :clearable="true"
          :options="countryList"
          :reduce="(countryOperationId) => countryOperationId.id"
          label="countryName"
        /> 
      <label class="vs-input--label">{{
          $t(resources.Currency.i18n) || resources.Currency.name
        }}</label>
        <v-select
          v-model="currencyId"
          :clearable="true"
          :options="currencyList"
          :reduce="(currencyId) => currencyId.id"
          label="display"
        />
      <div class="vx-col w-full mb-1">
        <label class="vs-input--label">{{$t(resources.Product.i18n) || resources.Product.name}}</label>
        <v-select
          v-model="productId"
          :clearable="false"
          :options="productOption"
          :reduce="item => item.id"
          label="name"
          :placeholder="$t(resources.Select.i18n) || resources.Select.name"
        />
      </div>
      <div class="vx-col w-full mb-4">
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-percent"
          icon-no-border
          :label="$t(resources.Percent.i18n) || resources.Percent.name"
          v-model="percentValue"
          :value="percentValue"
          :maxlength="5"
          @keypress="onlyNumber"
        />
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-button
            class="mr-3 mb-2"
            @click="save"
          >{{$t(resources.Save.i18n) || resources.Save.name}}</vs-button>
          <vs-button
            color="warning"
            class="mr-3 mb-2"
            @click="disabled(`${chargeId}`)"
          >{{$t(resources.Disabled.i18n) || resources.Disabled.name}}</vs-button>
          <vs-button
            class="mr-3 mb-2"
            style
            color="rgb(174,174,174)"
            @click="popupFee = false"
          >{{$t(resources.Cancel.i18n) || resources.Cancel.name}}</vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      :title="$t(resources.TermsConditionsTitle.i18n) || resources.TermsConditionsTitle.name"
      :active.sync="popupInfo"
    >
      <div class="vx-row">
        <div class="vx-col w-full mb-base divTerms">
          <label
            class="text-red"
          >{{$t(resources.TermsConditionsContent1.i18n) || resources.TermsConditionsContent1.name}}</label>
          <br />
          <br />
          <label
            class="text-blue"
          >{{$t(resources.TermsConditionsContent2.i18n) || resources.TermsConditionsContent2.name}}</label>
          <br />
          <br />
          <ul class="a">
            <li>{{$t(resources.TermsConditionsContent3.i18n) || resources.TermsConditionsContent3.name}}</li>
            <li>{{$t(resources.TermsConditionsContent4.i18n) || resources.TermsConditionsContent4.name}}</li>
            <li>{{$t(resources.TermsConditionsContent5.i18n) || resources.TermsConditionsContent5.name}}</li>
            <li>{{$t(resources.TermsConditionsContent6.i18n) || resources.TermsConditionsContent6.name}}</li>
          </ul>
          <br />
          <label
            class="text-blue"
          >{{$t(resources.TermsConditionsContent7.i18n) || resources.TermsConditionsContent7.name}}</label>
          <br />
          <br />
          <ul class="a">
            <li>{{$t(resources.TermsConditionsContent8.i18n) || resources.TermsConditionsContent8.name}}</li>
          </ul>
          <br />
          <label
            class="text-blue"
          >{{$t(resources.TermsConditionsContent9.i18n) || resources.TermsConditionsContent9.name}}</label>
          <br />
          <br />
          <ul class="a">
            <li>{{$t(resources.TermsConditionsContent10.i18n) || resources.TermsConditionsContent10.name}}</li>
            <li>{{$t(resources.TermsConditionsContent11.i18n) || resources.TermsConditionsContent11.name}}</li>
          </ul>
          <br />
          <label
            class="text-blue"
          >{{$t(resources.TermsConditionsContent12.i18n) || resources.TermsConditionsContent12.name}}</label>
          <br />
          <br />
          <ul class="a">
            <li>{{$t(resources.TermsConditionsContent13.i18n) || resources.TermsConditionsContent13.name}}</li>
          </ul>
          <br />
          <label
            class="text-red"
          >{{$t(resources.TermsConditionsContent14.i18n) || resources.TermsConditionsContent14.name}}</label>
          <br />
          <br />
          <ul>
            <li>{{$t(resources.TermsConditionsContent15.i18n) || resources.TermsConditionsContent15.name}}</li>
          </ul>
        </div>
      </div>
      <div class="vx-row mb-1">
        <div class="vx-col w-full">
          <vs-button
            class="mr-3 mb-2"
            @click="popupInfo = false"
          >{{$t(resources.Accept.i18n) || resources.Accept.name}}</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";

export default {
  components: {
    AgGridVue,
    "v-select": vSelect
  },
  data() {
    return {
      alerts: alerts,
      resources: resources,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      chargeId: 0,
      listData: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "id",
          field: "id",
          hide: true
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "active",
          width: 100,
          cellRenderer: function(event) {
            let object = "";
            switch (event.node.data.active) {
              case false:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              case true:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
              default:
                break;
            }
            return object;
          }
        },
        {
          headerName: this.$i18n.t("Product"),
          field: "productName",
          filter: true,
          width: 130
        },
        {
          headerName: this.$i18n.t("CountryOperation"),
          field: "countryOperation",
          filter: true
        },
        {
          headerName: this.$i18n.t("CurrencyCode"),
          field: "currencyCode",
          filter: true
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "chargeTypeName",
          filter: true
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "value",
          filter: true,
          width: 120,
          cellRenderer: function(event) {
            return `%${event.node.data.value}`;
          }
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "createdDate",
          filter: true
        },
        {
          headerName: this.$i18n.t("ChangeDate"),
          field: "modifiedDate",
          filter: true
        }
      ],
      popupFee: false,
      popupInfo: false,
      percentValue: 0,
      productId: 2,
      productOption: [],
      chargeTypeId: 2,
      status: "1",
      statusList: [
        {
          text: "All",
          value: "",
          i18n: "All"
        },
        {
          text: "Active",
          value: "1",
          i18n: "Active"
        },
        {
          text: "Inactive",
          value: "0",
          i18n: "Inactive"
        }
      ],
      currencyId: 24,
      currencyList: [],
      countryOperationId: 0,
      countryList : []
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("firstname", null);
      } else this.gridOptions.columnApi.setColumnPinned("firstname", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.totalNumberPages)
        return this.totalNumberPages.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.NumberPage = val;
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  created: async function() {    
    await this.list();    
    await this.getProducts();
    await this.getCountryOperation();  
    await this.getCurrency();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    async getProducts() {            
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/ByType/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.productOption = [];
          } else {
            this.productOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async list() {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ChargeSetting/ByIta/${this.$i18n.locale.toUpperCase()}/${
          this.itaCode
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {          
          if (result.data.length > 0) {
            if (this.status == "") this.listData = result.data;
            else if (this.status == "1")
              this.listData = result.data.filter(x => x.active);
            else this.listData = result.data.filter(x => !x.active);
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.listData = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async save() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}ChargeSetting`,
        data: {
          chargeTypeId: this.chargeTypeId,
          itaCode: this.itaCode,
          productId: this.productId,
          value: this.percentValue,
          createdBy: this.email,
          language: this.$i18n.locale.toUpperCase(),
          countryOperationId: this.countryOperationId,
          currencyId: this.currencyId
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupFee = false;
            this.list();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async onRowSelected(event) {      
      if (event.node.data.active) {
        this.popupFee = true;
        this.chargeId = event.node.data.id;
        this.chargeTypeId = 2;
        this.productId = event.node.data.productId;
        this.percentValue = event.node.data.value;
        this.countryOperationId = event.node.data.countryOperationId;
        this.currencyId = event.node.data.currencyId;
      }
    },

    async disabled(id) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ChargeSetting/Disabled/${this.$i18n.locale.toUpperCase()}/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          this.$vs.notify({
            title: this.$i18n.t("Success"),
            text: result.data.message,
            color: "success",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.popupFee = false;
          await this.list();
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountryOperation() {                
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {          
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.countryList = [];
          } else {
            this.countryList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCurrency() {                
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Currency/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.currencyList = [];
          } else {
            this.currencyList = result.data.filter(x => x.code != 'GTQ');
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },
  
  },  
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>