var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        {
          staticClass: "mb-4",
          attrs: { "vs-type": "flex", "vs-justify": "center" }
        },
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "12"
              }
            },
            [
              !_vm.dataImg
                ? _c("vs-avatar", {
                    staticClass: "user-profile-img",
                    attrs: { src: _vm.imageUrl, size: "200px" }
                  })
                : _c("vs-avatar", {
                    staticClass: "user-profile-img",
                    attrs: { src: _vm.dataImg, size: "200px" }
                  })
            ],
            1
          ),
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center"
              }
            },
            [
              !_vm.dataImg
                ? _c(
                    "div",
                    { staticClass: "upload-img mt-5" },
                    [
                      _c("input", {
                        ref: "uploadImgInput",
                        staticClass: "hidden",
                        attrs: { type: "file", accept: "image/*" },
                        on: { change: _vm.updateCurrImg }
                      }),
                      _c(
                        "vs-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$refs.uploadImgInput.click()
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.UploadFile.i18n) ||
                                _vm.resources.UploadFile.name
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm.dataImg
            ? _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "upload-img mt-5" },
                    [
                      _c("vs-button", { on: { click: _vm.getuploadForm } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Save.i18n) ||
                              _vm.resources.Save.name
                          )
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full mb-base" },
          [
            _c(
              "vx-card",
              [
                _c("h4", { staticClass: "text-center text-success mb-2" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.name) + " " + _vm._s(_vm.lastName))
                  ])
                ]),
                _c(
                  "p",
                  {
                    staticClass: "font-semibold text-center text-warning mb-4"
                  },
                  [
                    _c("vs-icon", {
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-calendar",
                        size: "small",
                        color: "rgba(var(--vs-warning), 1)"
                      }
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(_vm.resources.ExpirationDate.i18n) ||
                            _vm.resources.ExpirationDate.name
                        ) +
                        ": " +
                        _vm._s(_vm.renewalDate) +
                        " |\n          " +
                        _vm._s(
                          _vm.$t(_vm.resources.AffiliationDate.i18n) ||
                            _vm.resources.AffiliationDate.name
                        ) +
                        ": " +
                        _vm._s(_vm.affiliationDate) +
                        "\n        "
                    )
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    staticClass:
                      "font-semibold text-center text-warning mb-base"
                  },
                  [
                    _vm.changeRenewalDate == null || _vm.changeRenewalDate == ""
                      ? _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text:
                                _vm.$t(_vm.resources.Update.i18n) ||
                                _vm.resources.Update.name
                            }
                          },
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.popupRenewalDate = true
                                  }
                                }
                              },
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "EditIcon" }
                                }),
                                _c("br"),
                                _vm._v(
                                  "Cambia aquí el día\n              de pago de tu mensualidad.\n            "
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/3 w-full mb-2" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-hash",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.DocumentType.i18n) ||
                            _vm.resources.DocumentType.name,
                          value: _vm.documentType,
                          disabled: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-col md:w-1/3 w-full mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-hash",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.DocumentNumber.i18n) ||
                              _vm.resources.DocumentNumber.name,
                            value: _vm.documentNumber,
                            disabled: ""
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "vx-col md:w-1/3 w-full mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-map",
                            "icon-no-border": "",
                            disabled: "",
                            label:
                              _vm.$t(_vm.resources.MembershipCountry.i18n) ||
                              _vm.resources.MembershipCountry.name
                          },
                          model: {
                            value: _vm.countryOperationEntryName,
                            callback: function($$v) {
                              _vm.countryOperationEntryName = $$v
                            },
                            expression: "countryOperationEntryName"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/3 w-full mb-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.BirthDay.i18n) ||
                              _vm.resources.BirthDay.name
                          )
                        )
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          clearable: false,
                          options: _vm.dayList,
                          reduce: function(data) {
                            return data.value
                          },
                          label: "text",
                          name: "birthDay"
                        },
                        model: {
                          value: _vm.birthDay,
                          callback: function($$v) {
                            _vm.birthDay = $$v
                          },
                          expression: "birthDay"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("birthDay"),
                              expression: "errors.has('birthDay')"
                            }
                          ],
                          staticClass: "text-danger text-sm"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("birthDay")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/3 w-full mb-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.BirthMonth.i18n) ||
                              _vm.resources.BirthMonth.name
                          )
                        )
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          clearable: false,
                          options: _vm.monthList,
                          reduce: function(data) {
                            return data.value
                          },
                          label: "text",
                          name: "birthMonth"
                        },
                        model: {
                          value: _vm.birthMonth,
                          callback: function($$v) {
                            _vm.birthMonth = $$v
                          },
                          expression: "birthMonth"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("birthMonth"),
                              expression: "errors.has('birthMonth')"
                            }
                          ],
                          staticClass: "text-danger text-sm"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("birthMonth")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/3 w-full mb-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.BirthYear.i18n) ||
                              _vm.resources.BirthYear.name
                          )
                        )
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          clearable: false,
                          options: _vm.yearList,
                          reduce: function(data) {
                            return data.value
                          },
                          label: "text",
                          name: "birthYear"
                        },
                        model: {
                          value: _vm.birthYear,
                          callback: function($$v) {
                            _vm.birthYear = $$v
                          },
                          expression: "birthYear"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("birthYear"),
                              expression: "errors.has('birthYear')"
                            }
                          ],
                          staticClass: "text-danger text-sm"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("birthYear")))]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-col md:w-1/3 w-full mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            type: "email",
                            "icon-pack": "feather",
                            icon: "icon-mail",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.Email.i18n) ||
                              _vm.resources.Email.name
                          },
                          model: {
                            value: _vm.email,
                            callback: function($$v) {
                              _vm.email = $$v
                            },
                            expression: "email"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "vx-col md:w-1/3 w-full mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|numeric",
                              expression: "'required|numeric'"
                            }
                          ],
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-smartphone",
                            label:
                              _vm.$t(_vm.resources.Mobile.i18n) ||
                              _vm.resources.Mobile.name,
                            name: "phone"
                          },
                          model: {
                            value: _vm.phone,
                            callback: function($$v) {
                              _vm.phone = $$v
                            },
                            expression: "phone"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("phone"),
                            expression: "errors.has('phone')"
                          }
                        ],
                        staticClass: "text-danger text-sm"
                      },
                      [_vm._v(_vm._s(_vm.errors.first("phone")))]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/3 w-full mb-2" },
                    [
                      _c(
                        "vs-select",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "w-full",
                          attrs: {
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.Gender.i18n) ||
                              _vm.resources.Gender.name,
                            name: "gender"
                          },
                          model: {
                            value: _vm.gender,
                            callback: function($$v) {
                              _vm.gender = $$v
                            },
                            expression: "gender"
                          }
                        },
                        _vm._l(_vm.genderOption, function(item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: {
                              value: item.value,
                              text: _vm.$t(item.i18n) || item.text
                            }
                          })
                        }),
                        1
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("gender"),
                              expression: "errors.has('gender')"
                            }
                          ],
                          staticClass: "text-danger text-sm"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("gender")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/3 w-full mb-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.ResidenceCountry.i18n) ||
                              _vm.resources.ResidenceCountry.name
                          )
                        )
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          clearable: false,
                          options: _vm.countryList,
                          reduce: function(country) {
                            return country.id
                          },
                          label: "name",
                          hint: "" + _vm.countryId,
                          name: "countryId"
                        },
                        on: {
                          input: function($event) {
                            return _vm.getStateByCountry("" + _vm.countryId)
                          }
                        },
                        model: {
                          value: _vm.countryId,
                          callback: function($$v) {
                            _vm.countryId = $$v
                          },
                          expression: "countryId"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("countryId"),
                              expression: "errors.has('countryId')"
                            }
                          ],
                          staticClass: "text-danger text-sm"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("countryId")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/3 w-full mb-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.ResidenceState.i18n) ||
                              _vm.resources.ResidenceState.name
                          )
                        )
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          clearable: false,
                          options: _vm.stateCountry,
                          reduce: function(state) {
                            return state.id
                          },
                          label: "name",
                          hint: "" + _vm.stateId,
                          name: "state"
                        },
                        on: {
                          input: function($event) {
                            return _vm.getCityByState("" + _vm.stateId)
                          }
                        },
                        model: {
                          value: _vm.stateId,
                          callback: function($$v) {
                            _vm.stateId = $$v
                          },
                          expression: "stateId"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("state"),
                              expression: "errors.has('state')"
                            }
                          ],
                          staticClass: "text-danger text-sm"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("state")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/3 w-full mb-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.ResidenceCity.i18n) ||
                              _vm.resources.ResidenceCity.name
                          )
                        )
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          clearable: false,
                          options: _vm.destinationList,
                          reduce: function(city) {
                            return city.id
                          },
                          label: "name",
                          name: "city"
                        },
                        model: {
                          value: _vm.cityId,
                          callback: function($$v) {
                            _vm.cityId = $$v
                          },
                          expression: "cityId"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("city"),
                              expression: "errors.has('city')"
                            }
                          ],
                          staticClass: "text-danger text-sm"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("city")))]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-col md:w-1/3 w-full mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                required: true,
                                regex: /^([a-zA-Z0-9- ]+)$/
                              },
                              expression:
                                "{ required: true, regex: /^([a-zA-Z0-9- ]+)$/ }"
                            }
                          ],
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-map-pin",
                            label:
                              _vm.$t(_vm.resources.Address.i18n) ||
                              _vm.resources.Address.name,
                            maxlength: "99",
                            name: "address"
                          },
                          model: {
                            value: _vm.address,
                            callback: function($$v) {
                              _vm.address = $$v
                            },
                            expression: "address"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("address"),
                            expression: "errors.has('address')"
                          }
                        ],
                        staticClass: "text-danger text-sm"
                      },
                      [_vm._v(_vm._s(_vm.errors.first("address")))]
                    )
                  ]),
                  _c("div", { staticClass: "vx-col md:w-1/3 w-full mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|min:5|numeric",
                              expression: "'required|min:5|numeric'"
                            }
                          ],
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-hash",
                            type: "number",
                            label:
                              _vm.$t(_vm.resources.ZipCode.i18n) ||
                              _vm.resources.ZipCode.name,
                            name: "zipCode"
                          },
                          model: {
                            value: _vm.zipCode,
                            callback: function($$v) {
                              _vm.zipCode = $$v
                            },
                            expression: "zipCode"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("zipCode"),
                            expression: "errors.has('zipCode')"
                          }
                        ],
                        staticClass: "text-danger text-sm"
                      },
                      [_vm._v(_vm._s(_vm.errors.first("zipCode")))]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/3 w-full mb-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Nationality.i18n) ||
                              _vm.resources.Nationality.name
                          )
                        )
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          clearable: false,
                          options: _vm.countryList,
                          reduce: function(country) {
                            return country.id
                          },
                          label: "name",
                          name: "nationalityId"
                        },
                        model: {
                          value: _vm.nationalityId,
                          callback: function($$v) {
                            _vm.nationalityId = $$v
                          },
                          expression: "nationalityId"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("nationalityId"),
                              expression: "errors.has('nationalityId')"
                            }
                          ],
                          staticClass: "text-danger text-sm"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("nationalityId")))]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-col md:w-1/3 w-full mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                required: true,
                                regex: /^([a-zA-Z0-9- ]+)$/
                              },
                              expression:
                                "{ required: true, regex: /^([a-zA-Z0-9- ]+)$/ }"
                            }
                          ],
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-user",
                            label:
                              (_vm.$t(_vm.resources.Nickname.i18n) ||
                                _vm.resources.Nickname.name) + " - One2trip",
                            maxlength: "50",
                            name: "nickname"
                          },
                          model: {
                            value: _vm.nickname,
                            callback: function($$v) {
                              _vm.nickname = $$v
                            },
                            expression: "nickname"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("nickname"),
                            expression: "errors.has('nickname')"
                          }
                        ],
                        staticClass: "text-danger text-sm"
                      },
                      [_vm._v(_vm._s(_vm.errors.first("nickname")))]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/4 w-full" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.CountryOperation.i18n) ||
                              _vm.resources.CountryOperation.name
                          ) + "\n              - One2trip"
                        )
                      ]),
                      _c("v-select", {
                        attrs: {
                          clearable: false,
                          options: _vm.countryOperationOption,
                          reduce: function(data) {
                            return data.countryId
                          },
                          label: "display"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function(option) {
                              return [
                                _c("img", {
                                  staticClass: "h-6 w-6",
                                  attrs: { src: option.iconUrl }
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(option.display) +
                                    "\n              "
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.countryOperationId,
                          callback: function($$v) {
                            _vm.countryOperationId = $$v
                          },
                          expression: "countryOperationId"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "vx-col mb-2 mt-5",
                    staticStyle: { "text-align": "right" }
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "primary", type: "filled" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.validData()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(_vm.resources.Save.i18n) ||
                                _vm.resources.Save.name
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  {
                    staticClass: "mb-4",
                    attrs: { "vs-type": "flex", "vs-justify": "center" }
                  },
                  [
                    _c(
                      "vs-col",
                      {
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "center",
                          "vs-align": "center",
                          "vs-w": "3"
                        }
                      },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: {
                              type: "gradient",
                              color: "primary",
                              "icon-pack": "material-icons",
                              icon: "notifications_active",
                              size: "large",
                              href:
                                "https://t.me/VisionTravelbot?start=" +
                                _vm.itaCode
                            }
                          },
                          [_vm._v("Telegram")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "h4",
                  {
                    staticClass: "text-center text-primary text-semibold mb-2"
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(_vm.alerts.MsgJoinTelegram.i18n) ||
                            _vm.alerts.MsgJoinTelegram.name
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _c("h4", { staticClass: "text-primary text-center" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.alerts.MsgWhatWait.i18n) ||
                          _vm.alerts.MsgWhatWait.name
                      )
                    )
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full text-right" },
          [
            _c(
              "vs-button",
              {
                attrs: { color: "rgb(174,174,174)", type: "filled" },
                on: {
                  click: function($event) {
                    return _vm.$router.push("/")
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Return.i18n) ||
                      _vm.resources.Return.name
                  )
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.ExpirationDate.i18n) ||
              _vm.resources.ExpirationDate.name,
            active: _vm.popupRenewalDate
          },
          on: {
            "update:active": function($event) {
              _vm.popupRenewalDate = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mb-4" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Day.i18n) || _vm.resources.Day.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: false,
                    options: _vm.dayList,
                    reduce: function(data) {
                      return data.value
                    },
                    label: "text"
                  },
                  model: {
                    value: _vm.dayRenewal,
                    callback: function($$v) {
                      _vm.dayRenewal = $$v
                    },
                    expression: "dayRenewal"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "vs-button",
            { staticClass: "mb-base", on: { click: _vm.setRenewalDay } },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.Save.i18n) || _vm.resources.Save.name
                )
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }