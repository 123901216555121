var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-tabs",
        {
          key: _vm.isSmallerScreen,
          staticClass: "tabs-shadow-none",
          attrs: {
            position: _vm.isSmallerScreen ? "top" : "left",
            id: "profile-tabs"
          }
        },
        [
          _c(
            "vs-tab",
            {
              attrs: {
                "icon-pack": "feather",
                icon: "icon-user",
                label: !_vm.isSmallerScreen
                  ? _vm.$t(_vm.resources.BasicInformation.i18n) ||
                    _vm.resources.BasicInformation.name
                  : ""
              }
            },
            [
              _c(
                "div",
                { staticClass: "tab-general md:ml-4 md:mt-0 mt-4 ml-0" },
                [_c("profile-information")],
                1
              )
            ]
          ),
          _c(
            "vs-tab",
            {
              attrs: {
                "icon-pack": "feather",
                icon: "icon-credit-card",
                label: !_vm.isSmallerScreen
                  ? _vm.$t(_vm.resources.MyWayTopay.i18n) ||
                    _vm.resources.MyWayTopay.name
                  : ""
              }
            },
            [
              _c(
                "div",
                { staticClass: "tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0" },
                [_c("preferences-payments")],
                1
              )
            ]
          ),
          _vm.contactType == _vm.database.contactType.Ita
            ? _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-settings",
                    label: !_vm.isSmallerScreen
                      ? _vm.$t(_vm.resources.CustomizedFee.i18n) ||
                        _vm.resources.CustomizedFee.name
                      : ""
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0" },
                    [_c("profile-config-fee")],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }